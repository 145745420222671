.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.0625rem;
  background: #333;
  position: fixed;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 4.0625rem;
}

.navbar_link {
  color: #0082c8;
  font-size: 1.25rem;
  margin-left: 1.25rem;
  text-decoration: none;
  cursor: pointer;
}
